import { createReducer } from '@reduxjs/toolkit';
import { UserState, LoginRequest, LoginSuccess, LoginFailure, LoadUserSuccess, LoadUserFailure } from '../../types/Reducer';

const initialState: UserState = {
  loading: false,
  user: null,
  error: null,
  isAuthenticated: false,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("SignupRequest", (state) => {
      state.loading = true;
    })

    .addCase("SignupSuccess", (state, action: any) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    })

    .addCase("SignupFailure", (state, action: any) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })

    .addCase("LoginRequest", (state) => {
      state.loading = true;
    })

    .addCase("LoginSuccess", (state, action: any) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    })

    .addCase("LoginFailure", (state, action: any) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })

    .addCase("LoadUserRequest", (state) => {
      state.loading = true;
      state.error = null;
    })

    .addCase("LoadUserSuccess", (state, action: any) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    })

    .addCase("LoadUserFailure", (state, action: any) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })

    .addCase("LogoutUserRequest", (state, action: any) => {
      state.loading = true;
    })

    .addCase("LogoutUserSuccess", (state, action: any) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
    })

    .addCase("LogoutUserFailure", (state, action: any) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = true;
    })

    .addCase("clearErrors", (state, action: any) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = true;
    })
});
