import menImg from "../../images/loder/1.png"
import girlImg from "../../images/loder/2.png"
import heartImg from "../../images/loder/3.png"

const LoaderPage = () => {
 

  return (
    <div id="preloader">
    <div className="plod">
      <span className="lod1">
        <img src={menImg} alt="" loading="lazy" />
      </span>
      <span className="lod2">
        <img src={girlImg}alt="" loading="lazy" />
      </span>
      <span className="lod3">
        <img src={heartImg} alt="" loading="lazy" />
       </span>
    </div>
  </div>
  )
}

export default LoaderPage