import React, { useEffect, useState } from 'react';
import eyeOn from "../images/signupandlogin/eyeOn.png"
import eyeOff from "../images/signupandlogin/eyeOff.png"
import "../css/signup.css"
import { FormData } from '../types/signup';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../types/Reducer';
import Alert from '@mui/material/Alert';
import { registerUser } from 'src/Redux/Actions/User';

const SignUp = () => {
  const [formData, setFormData] = useState<FormData>({ username: '', password: '' });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ username?: string; password?: string }>({});
  const dispatch = useDispatch()
  const {loading, error} = useSelector((state:RootState)=>state.user);
  const[errorMessage, setErrorMessage] = useState<String>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrors({ username: '', password: '' })
  };


  const validateForm = (): boolean => {
    const newErrors: { username?: string; password?: string } = {};

    if (!formData.username) {
      newErrors.username = 'Username is required';
    }else if(formData.username.length < 4){
      newErrors.username = 'Username must be at least 4 characters';
    }

   
    if (formData.password.length < 4) {
      newErrors.password = 'Password must be at least 4 characters';
      setShowPassword(true)
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const register = async () => { 
    if (validateForm()) {
        registerUser(dispatch, formData);
    }
  }

  useEffect(() => {
    if (error != null &&  String(error) != "Please Login to access this resource")  { 
      setErrorMessage(String(error));
      dispatch({ type: "clearErrors" });
      setTimeout(() => {
        setErrorMessage("")
      }, 1900);
    }
  }, [dispatch, error]);


  return (
    <section>
      <div className="login">
        <div className="container">
          <div className="row"> 
            <div className="inn">
              <div className="lhs">
                <div className="tit">
                  <h2>
                    Now <b>Find your life partner</b> Easy and fast.
                  </h2>
                </div>
                <div className="im">
                  <img src="images/login-couple.png" alt="" />
                </div>
                <div className="log-bg">&nbsp;</div>
              </div>
              <div className="rhs">
                <div>
                  <div className="form-tit">
                    <h4>Start for free</h4>
                    <h1>Sign up to Matrimony</h1>
                    <p>
                      Already a member? <a href="login">Login</a>
                    </p>
                  </div>
                  <div className="form-login">
                    {/* <form  onSubmit={signUp}> */}
                    <div className="form-group">
                    {
                      errorMessage !== "" &&  <Alert severity="error" style={{marginBottom:"4%"}}>{errorMessage}</Alert>
                    }
                      <label className="lb">User Name:</label>
                      <input
                        type="text"
                        className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                        name="username"
                        placeholder="Enter your full name"
                        value={errors.username != '' ? errors.username : formData.username}
                        onChange={handleChange}
                        maxLength={35}
                      />
                    </div>
                
                    <div className="form-group">
                      <label className="lb">Password:</label>
                      <div className="password-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                          id="pwd"
                          placeholder="Enter password"
                          name="password"
                          value={errors.password != '' ? errors.password : formData.password}
                          onChange={handleChange}
                          maxLength={25}
                        />
                        {
                          errors.password === '' &&
                          <img
                          src={showPassword ? eyeOn : eyeOff}
                          alt="Toggle password visibility"
                          onClick={togglePasswordVisibility}
                          className="password-toggle-icon"
                        />
                        }
                     
                      </div>
                    </div>

                    <div className="form-group form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="agree"
                        />{" "}
                        Creating an account means you’re okay with our{" "}
                        <a href="#!">Terms of Service</a>, Privacy Policy, and our
                        default Notification Settings.
                      </label>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={register}>
                      Create Account
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default SignUp