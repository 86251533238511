import React, { useEffect, useState } from 'react' 
import { FormData } from '../types/signup'; 
import { useDispatch } from 'react-redux';
import { loginUser } from 'src/Redux/Actions/User';
import { useSelector } from 'react-redux';
import { RootState } from 'src/types/Reducer';
import Alert from '@mui/material/Alert';
import eyeOn from "../images/signupandlogin/eyeOn.png"
import eyeOff from "../images/signupandlogin/eyeOff.png"

  const Login = () => {
  const [formData, setFormData] = useState<FormData>({ username: '', password: '' });
  const [errors, setErrors] = useState<{ username?: string; password?: string }>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const[errorMessage, setErrorMessage] = useState<String>("")
  const {error} = useSelector((state:RootState)=>state.user);
  const dispatch = useDispatch()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrors({ username: '', password: '' })
  };

  const validateForm = (): boolean => {
    const newErrors: { username?: string; password?: string } = {};
    if (!formData.username) {
      newErrors.username = 'Username is required';
    } 

    if (!formData.password) {
      newErrors.password = 'Password is required';
      setShowPassword(true);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
 
  const login= async()=>{
    if (validateForm()) {
      loginUser(dispatch, formData);
    }
  }
  
  useEffect(() => {
    if (error != null &&  String(error) != "Please Login to access this resource")  { 
      setErrorMessage(String(error));
      dispatch({ type: "clearErrors" });
      setTimeout(() => {
        setErrorMessage("")
      }, 1900);
    }
  }, [dispatch, error]);

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <div>
  <section>
  <div className="login">
    <div className="container">
      <div className="row">
        <div className="inn">
          <div className="lhs">
            <div className="tit">
              <h2>
                Now{" "}
                <b>
                  Find <br /> your life partner
                </b>{" "}
                Easy and fast.
              </h2>
            </div>
            <div className="im">
              <img src="images/login-couple.png" alt="" />
            </div>
            <div className="log-bg">&nbsp;</div>
          </div>
          <div className="rhs">
            <div>
              <div className="form-tit">
                <h4>Start for free</h4>
                <h1>Sign in to Matrimony</h1>
                <p>
                  Not a member? <a href="sign-up">Sign up now</a>
                </p>
              </div>
              <div className="form-login">
                {
                  errorMessage !== "" &&  <Alert severity="error" style={{marginBottom:"4%"}}>{errorMessage}</Alert>
                }

                {/* <form action='/my-profile' onSubmit={(e)=>login(e)}> */}
                  <div className="form-group">
                    <label className="lb">Email:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                      name="username"
                      placeholder="Enter Username"
                      value={errors.username != '' ? errors.username : formData.username}   
                      onChange={handleChange}
                      maxLength={35}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label className="lb">Password:</label>
                    <input
                     type={showPassword ? "text" : "password"}
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      id="pwd"
                      placeholder="Enter password"
                      name="password"
                      maxLength={25}
                      value={errors.password != '' ? errors.password : formData.password}
                      onChange={handleChange}
                    />
                    {
                          errors.password === '' &&
                          <img
                          src={showPassword ? eyeOn : eyeOff}
                          alt="Toggle password visibility"
                          onClick={togglePasswordVisibility}
                          className="password-toggle-icon"
                        />
                        }
                  </div> */}


<div className="form-group">
                      <label className="lb">Password:</label>
                      <div className="password-container">
                      <input
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                          id="pwd"
                          placeholder="Enter password"
                          name="password"
                          value={errors.password != '' ? errors.password : formData.password}
                          onChange={handleChange}
                          maxLength={25}
                        />
                        {
                          errors.password === '' &&
                          <img
                          src={showPassword ? eyeOn : eyeOff}
                          alt="Toggle password visibility"
                          onClick={togglePasswordVisibility}
                          className="password-toggle-icon"
                        />
                        }
                     
                      </div>
                    </div>


                  <div className="form-group form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="agree"
                      />{" "}
                      Remember me
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary" onClick={login}>
                    Sign in
                  </button>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Login