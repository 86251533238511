 
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from '../../types/Reducer';

 

// Configure the store with middleware
const store = configureStore({
  reducer: rootReducer, 
});

export default store;

export type AppDispatch = typeof store.dispatch;
