import React, { useEffect, useState } from 'react'
import logo from "../../images/logo-b.png"
import search from "../../images/icon/search.svg"
import img1 from "../../images/profiles/1.jpg"
import img2 from "../../images/couples/1.jpg"
import img3 from "../../images/couples/3.jpg"
import img4 from "../../images/couples/4.jpg"
import img5 from "../../images/icon/menu.svg"
import img6 from "../../images/icon/users.svg"
import img7 from "../../images/icon/close.svg"

import $ from "jquery"
import LoaderPage from './LoaderPage'
import { useSelector } from 'react-redux'
import { RootState } from 'src/types/Reducer'

const Header = () => {
 
  const { loading } = useSelector((state: RootState) => state.user); 
 

  useEffect(() => {
    var s = $(".hom-top");
    $(window).scroll(function () {
      var windowpos: number = $(window).scrollTop() || 0;
      if (windowpos >= 200) {
        s.addClass("act");
      } else {
        s.removeClass("act");
      }

      var _wtpx = "-" + windowpos / 3 + "px";
      var _wtpx1 = windowpos / 3 + "px";
      $(".wedd-vid-tre-1").css({
        transform: 'translateX(' + _wtpx + ')'
      });
      $(".wedd-vid-tre-2").css({
        transform: 'translateX(' + _wtpx1 + ')'
      });
    });

    return () => {
      $(window).off('scroll');
    };
  }, []);


  return (

     <div>
 
   { loading &&
       <LoaderPage/>
   }
 

  <div className="pop-bg" />
 

  {/* END PRELOADER */}
  {/* SEARCH */}
  <div className="pop-search">
    <span className="ser-clo">+</span>
    <div className="inn">
      <form>
        <input type="text" placeholder="Search here..." />
      </form>
      <div className="rel-sear">
        <h4>Top searches:</h4>
        <a href="all-profiles.html">Browse all profiles</a>
        <a href="all-profiles.html">Mens profile</a>
        <a href="all-profiles.html">Female profile</a>
        <a href="all-profiles.html">New profiles</a>
      </div>
    </div>
  </div>
  {/* END PRELOADER */}

           {/* HEADER & MENU */}
  <div className="head-top">
    <div className="container">
      <div className="row">
        <div className="lhs">
          <ul>
            <li>
              <a href="#!" className="ser-open">
                <i className="fa fa-search" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a href="about">About</a>
            </li>
            <li>
              <a href="faq">FAQ</a>
            </li>
            <li>
              <a href="contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="rhs">
          <ul>
            <li>
              <a href="tel:+9704462944">
                <i className="fa fa-phone" aria-hidden="true" />
                &nbsp;+01 5312 5312
              </a>
            </li>
            <li>
              <a href="mailto:info@example.com">
                <i className="fa fa-envelope-o" aria-hidden="true" />
                &nbsp; help@company.com
              </a>
            </li>
            <li>
              <a href="#!">
                <i className="fa fa-facebook" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a href="#!">
                <i className="fa fa-twitter" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a href="#!">
                <i className="fa fa-whatsapp" aria-hidden="true" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  {/* END HEADER & MENU */}
  {/* HEADER & MENU */}
  <div className="menu-pop menu-pop1">
    <span className="menu-pop-clo">
      <i className="fa fa-times" aria-hidden="true" />
    </span>
    <div className="inn">
      <img
        src={logo}
        alt=""
        loading="lazy"
        className="logo-brand-only"
      />
      <p>
        <strong>Best Wedding Matrimony</strong> lacinia viverra lectus. Fusce
        imperdiet ullamcorper metus eu fringilla.Lorem Ipsum is simply dummy
        text of the printing and typesetting industry.
      </p>
      <ul className="menu-pop-info">
        <li>
          <a href="#!">
            <i className="fa fa-phone" aria-hidden="true" />
            +92 (8800) 68 - 8960
          </a>
        </li>
        <li>
          <a href="#!">
            <i className="fa fa-whatsapp" aria-hidden="true" />
            +92 (8800) 68 - 8960
          </a>
        </li>
        <li>
          <a href="#!">
            <i className="fa fa-envelope-o" aria-hidden="true" />
            help@company.com
          </a>
        </li>
        <li>
          <a href="#!">
            <i className="fa fa-map-marker" aria-hidden="true" />
            3812 Lena Lane City Jackson Mississippi
          </a>
        </li>
      </ul>
      <div className="menu-pop-help">
        <h4>Support Team</h4>
        <div className="user-pro">
          <img src={img1} alt="" loading="lazy" />
        </div>
        <div className="user-bio">
          <h5>Ashley emyy</h5>
          <span>Senior personal advisor</span>
          <a href="enquiry" className="btn btn-primary btn-sm">
            Ask your doubts
          </a>
        </div>
      </div>
      <div className="menu-pop-soci">
        <ul>
          <li>
            <a href="#!">
              <i className="fa fa-facebook" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-twitter" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-whatsapp" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-youtube-play" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  {/* END HEADER & MENU */}
  {/* HEADER & MENU */}
  <div className="menu-pop menu-pop2">
    <span className="menu-pop-clo">
      <i className="fa fa-times" aria-hidden="true" />
    </span>
    <div className="inn">
      <div className="menu-pop-help">
        <h4>Support Team</h4>
        <div className="user-pro">
          <img src={img1} alt="" loading="lazy" />
        </div>
        <div className="user-bio">
          <h5>Ashley emyy</h5>
          <span>Senior personal advisor</span>
          <a href="enquiry" className="btn btn-primary btn-sm">
            Ask your doubts
          </a>
        </div>
      </div>
      <div className="menu-pop-soci">
        <ul>
          <li>
            <a href="#!">
              <i className="fa fa-facebook" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-twitter" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-whatsapp" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-youtube-play" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </li>
        </ul>
      </div>
      <div className="late-news">
        <h4>Latest news</h4>
        <ul>
          <li>
            <div className="rel-pro-img">
              <img src={img2} alt="" loading="lazy" />
            </div>
            <div className="rel-pro-con">
              <h5>Long established fact that a reader distracted</h5>
              <span className="ic-date">12 Dec 2023</span>
            </div>
            <a href="blog-detail" className="fclick" />
          </li>
          <li>
            <div className="rel-pro-img">
              <img src={img3} alt="" loading="lazy" />
            </div>
            <div className="rel-pro-con">
              <h5>Long established fact that a reader distracted</h5>
              <span className="ic-date">12 Dec 2023</span>
            </div>
            <a href="blog-detail" className="fclick" />
          </li>
          <li>
            <div className="rel-pro-img">
              <img src={img4} alt="" loading="lazy" />
            </div>
            <div className="rel-pro-con">
              <h5>Long established fact that a reader distracted</h5>
              <span className="ic-date">12 Dec 2023</span>
            </div>
            <a href="blog-detail" className="fclick" />
          </li>
        </ul>
      </div>
      {/* HELP BOX */}
      <div className="prof-rhs-help">
        <div className="inn">
          <h3>Tell us your Needs</h3>
          <p>Tell us what kind of service you are looking for.</p>
          <a href="enquiry">Register for free</a>
        </div>
      </div>
      {/* END HELP BOX */}
    </div>
  </div>
  {/* END HEADER & MENU */}
  {/* HEADER & MENU */}
  <div className="hom-top">
    <div className="container">
      <div className="row">
        <div className="hom-nav">
          {/* LOGO */}
          <div className="logo">
            <span className="menu desk-menu">
              <i />
              <i />
              <i />
            </span>
            <a href="index" className="logo-brand">
              <img
                src={logo}
                alt=""
                loading="lazy"
                className="ic-logo"
              />
            </a>
          </div>
          {/* TOP MENU */}
          <div className="bl">
            <ul>
              <li className="smenu-pare">
                <span className="smenu">Explore</span>
                <div className="smenu-open smenu-box">
                  <div className="container">
                    <div className="row">
                      <h4 className="tit">Explore category</h4>
                      <ul>
                        <li>
                          <div className="menu-box menu-box-2">
                            <h5>
                              Browse profiles{" "}
                              <span>1200+ Verified profiles</span>
                            </h5>
                            <span className="explor-cta">More details</span>
                            <a href="all-profiles" className="fclick" />
                          </div>
                        </li>
                        <li>
                          <div className="menu-box menu-box-1">
                            <h5>
                              Wedding page <span>Make reservation</span>
                            </h5>
                            <span className="explor-cta">More details</span>
                            <a href="wedding" className="fclick" />
                          </div>
                        </li>
                        <li>
                          <div className="menu-box menu-box-3">
                            <h5>
                              All Services<span>Lorem ipsum dummy</span>
                            </h5>
                            <span className="explor-cta">More details</span>
                            <a href="services" className="fclick" />
                          </div>
                        </li>
                        <li>
                          <div className="menu-box menu-box-4">
                            <h5>
                              Join Now <span>Lorem ipsum dummy</span>
                            </h5>
                            <span className="explor-cta">More details</span>
                            <a href="plans" className="fclick" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="smenu-pare">
                <span className="smenu">All pages</span>
                <div className="smenu-open smenu-multi">
                  <div className="container">
                    <div className="row">
                      <div className="multi-col">
                        <div className="inn">
                          <h4>Page sets 1</h4>
                          <ul>
                            <li>
                              <a href="all-profiles">All profiles</a>
                            </li>
                            <li>
                              <a href="profile-details">Profile details</a>
                            </li>
                            <li>
                              <a href="wedding">Wedding</a>
                            </li>
                            <li>
                              <a href="wedding-video">Wedding video</a>
                            </li>
                            <li>
                              <a href="services">Our Services</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="multi-col">
                        <div className="inn">
                          <h4>Page sets 2</h4>
                          <ul>
                            <li>
                              <a href="plans">Pricing plans</a>
                            </li>
                            <li>
                              <a href="login">Login</a>
                            </li>
                            <li>
                              <a href="sign-up">Sign-up</a>
                            </li>
                            <li>
                              <a href="photo-gallery">Photo gallery</a>
                            </li>
                            <li>
                              <a href="photo-gallery-1">Photo gallery 1</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="multi-col">
                        <div className="inn">
                          <h4>Page sets 3</h4>
                          <ul>
                            <li>
                              <a href="contact">Contact</a>
                            </li>
                            <li>
                              <a href="about">About</a>
                            </li>
                            <li>
                              <a href="blog">Blog</a>
                            </li>
                            <li>
                              <a href="blog-detail">Blog detail</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="multi-col">
                        <div className="inn">
                          <h4>Page sets 4</h4>
                          <ul>
                            <li>
                              <a href="enquiry">Ask your doubts</a>
                            </li>
                            <li>
                              <a href="make-reservation">
                                Make Reservation
                              </a>
                            </li>
                            <li>
                              <a href="faq">FAQ</a>
                            </li>
                            <li>
                              <a href="coming-soon" target="_blank">
                                Coming soon
                              </a>
                            </li>
                            <li>
                              <a href="404">404</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="multi-col full">
                        <div className="inn">
                          <h4>User dashboard pages</h4>
                          <ul>
                            <li>
                              <a href="user-dashboard">Dashboard</a>
                            </li>
                            <li>
                              <a href="my-profile">My profile</a>
                            </li>
                            <li>
                              <a href="user-interests">Interests</a>
                            </li>
                            <li>
                              <a href="user-chat">Chat lists</a>
                            </li>
                            <li>
                              <a href="user-plan">My plan details</a>
                            </li>
                            <li>
                              <a href="user-setting">Profile settings</a>
                            </li>
                            <li>
                              <a href="user-profile-edit">
                                Edit full profile
                              </a>
                            </li>
                            <li>
                              <a href="login">Sign in</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="smenu-pare">
                <span className="smenu">Top pages</span>
                <div className="smenu-open smenu-single">
                  <ul>
                    <li>
                      <a href="all-profiles">All profiles</a>
                    </li>
                    <li>
                      <a href="profile-details">Profile details</a>
                    </li>
                    <li>
                      <a href="wedding">Wedding</a>
                    </li>
                    <li>
                      <a href="blog">Blog</a>
                    </li>
                    <li>
                      <a href="blog-detail">Blog detail</a>
                    </li>
                    <li>
                      <a href="about">About</a>
                    </li>
                    <li>
                      <a href="contact">Contact</a>
                    </li>
                    <li>
                      <a href="photo-gallery">Photo gallery</a>
                    </li>
                    <li>
                      <a href="photo-gallery-1">Photo gallery 1</a>
                    </li>
                    <li>
                      <a href="login">Login</a>
                    </li>
                    <li>
                      <a href="sign-up">Sign-up</a>
                    </li>
                    <li>
                      <a href="plans">Pricing plans</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="plans">Plans</a>
              </li>
              <li>
                <a href="sign-up">Register</a>
              </li>
              <li className="smenu-pare">
                <span className="smenu">Dashboard</span>
                <div className="smenu-open smenu-single">
                  <ul>
                    <li>
                      <a href="user-dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="user-profile">My profile</a>
                    </li>
                    <li>
                      <a href="user-interests">Interests</a>
                    </li>
                    <li>
                      <a href="user-chat">Chat lists</a>
                    </li>
                    <li>
                      <a href="user-plan">My plan details</a>
                    </li>
                    <li>
                      <a href="user-setting">Profile settings</a>
                    </li>
                    <li>
                      <a href="user-profile-edit">Edit full profile</a>
                    </li>
                    <li>
                      <a href="login">Sign in</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          {/* USER PROFILE */}
          <div className="al">
            <div className="head-pro">
              <img src={img1} alt="" loading="lazy" />
              <b>Advisor</b>
              <br />
              <h4>Ashley emyy</h4>
              <span className="fclick" />
            </div>
          </div>
          {/*MOBILE MENU*/}
          <div className="mob-menu">
            <div className="mob-me-ic">
              <span className="ser-open mobile-ser">
                <img src={search} alt="" />
              </span>
              <span className="mobile-exprt" data-mob="dashbord">
                <img src={img5} alt="" />
              </span>
              <span className="mobile-menu" data-mob="mobile">
                <img src="images/icon/menu.svg" alt="" />
              </span>
            </div>
          </div>
          {/*END MOBILE MENU*/}
        </div>
      </div>
    </div>
  </div>
  {/* END HEADER & MENU */}
  {/* MOBILE MENU POPUP */}
  <div className="mob-me-all mobile_menu">
    <div className="mob-me-clo">
      <img src={img7} alt="" />
    </div>
    <div className="mv-bus">
      <h4>
        <i className="fa fa-globe" aria-hidden="true" /> EXPLORE CATEGORY
      </h4>
      <ul>
        <li>
          <a href="all-profiles">Browse profiles</a>
        </li>
        <li>
          <a href="wedding">Wedding page</a>
        </li>
        <li>
          <a href="services">All Services</a>
        </li>
        <li>
          <a href="plans">Join Now</a>
        </li>
      </ul>
      <h4>
        <i className="fa fa-align-center" aria-hidden="true" /> All Pages
      </h4>
      <ul>
        <li>
          <a href="all-profiles">All profiles</a>
        </li>
        <li>
          <a href="profile-details">Profile details</a>
        </li>
        <li>
          <a href="wedding">Wedding</a>
        </li>
        <li>
          <a href="wedding-video">Wedding video</a>
        </li>
        <li>
          <a href="services">Our Services</a>
        </li>
        <li>
          <a href="plans">Pricing plans</a>
        </li>
        <li>
          <a href="login">Login</a>
        </li>
        <li>
          <a href="sign-up">Sign-up</a>
        </li>
        <li>
          <a href="photo-gallery">Photo gallery</a>
        </li>
        <li>
          <a href="photo-gallery-1">Photo gallery 1</a>
        </li>
        <li>
          <a href="contact">Contact</a>
        </li>
        <li>
          <a href="about">About</a>
        </li>
        <li>
          <a href="blog">Blog</a>
        </li>
        <li>
          <a href="blog-detail">Blog detail</a>
        </li>
        <li>
          <a href="enquiry">Ask your doubts</a>
        </li>
        <li>
          <a href="make-reservation">Make Reservation</a>
        </li>
        <li>
          <a href="faq">FAQ</a>
        </li>
        <li>
          <a href="coming-soon" target="_blank">
            Coming soon
          </a>
        </li>
        <li>
          <a href="404">404</a>
        </li>
      </ul>
      <div className="menu-pop-help">
        <h4>Support Team</h4>
        <div className="user-pro">
          <img src={img1} alt="" loading="lazy" />
        </div>
        <div className="user-bio">
          <h5>Ashley emyy</h5>
          <span>Senior personal advisor</span>
          <a href="enquiry" className="btn btn-primary btn-sm">
            Ask your doubts
          </a>
        </div>
      </div>
      <div className="menu-pop-soci">
        <ul>
          <li>
            <a href="#!">
              <i className="fa fa-facebook" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-twitter" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-whatsapp" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-youtube-play" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="#!">
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </li>
        </ul>
      </div>
      <div className="late-news">
        <h4>Latest news</h4>
        <ul>
          <li>
            <div className="rel-pro-img">
              <img src={img2} alt="" loading="lazy" />
            </div>
            <div className="rel-pro-con">
              <h5>Long established fact that a reader distracted</h5>
              <span className="ic-date">12 Dec 2023</span>
            </div>
            <a href="blog-detail" className="fclick" />
          </li>
          <li>
            <div className="rel-pro-img">
              <img src={img3} alt="" loading="lazy" />
            </div>
            <div className="rel-pro-con">
              <h5>Long established fact that a reader distracted</h5>
              <span className="ic-date">12 Dec 2023</span>
            </div>
            <a href="blog-detail" className="fclick" />
          </li>
          <li>
            <div className="rel-pro-img">
              <img src={img4} alt="" loading="lazy" />
            </div>
            <div className="rel-pro-con">
              <h5>Long established fact that a reader distracted</h5>
              <span className="ic-date">12 Dec 2023</span>
            </div>
            <a href="blog-detail" className="fclick" />
          </li>
        </ul>
      </div>
      <div className="prof-rhs-help">
        <div className="inn">
          <h3>Tell us your Needs</h3>
          <p>Tell us what kind of service you are looking for.</p>
          <a href="enquiry">Register for free</a>
        </div>
      </div>
    </div>
  </div>
  {/* END MOBILE MENU POPUP */}


      {/* SEARCH */}
      <div className="pop-search">
  <span className="ser-clo">+</span>
  <div className="inn">
    <form>
      <input type="text" placeholder="Search here..." />
    </form>
    <div className="rel-sear">
      <h4>Top searches:</h4>
      <a href="all-profiles">Browse all profiles</a>
      <a href="all-profiles">Mens profile</a>
      <a href="all-profiles">Female profile</a>
      <a href="all-profiles">New profiles</a>
    </div>
  </div>
</div>

  {/* MOBILE USER PROFILE MENU POPUP */}
  <div className="mob-me-all dashbord_menu">
    <div className="mob-me-clo">
      <img src={img7} alt="" />
    </div>
    <div className="mv-bus">
      <div className="head-pro">
        <img src={img1}alt="" loading="lazy" />
        <b>user profile</b>
        <br />
        <h4>Ashley emyy</h4>
      </div>
      <ul>
        <li>
          <a href="login">Login</a>
        </li>
        <li>
          <a href="sign-up">Sign-up</a>
        </li>
        <li>
          <a href="plans">Pricing plans</a>
        </li>
        <li>
          <a href="all-profiles">Browse profiles</a>
        </li>
      </ul>
    </div>
  </div>
    </div>


  )
}

export default Header