import axios from "axios";
import { FormData } from "../../types/signup";
import { LoadUserFailure, LoadUserRequest, LoadUserSuccess, LoginFailure, LoginRequest, LoginSuccess, SignupFailure, SignupRequest, SignupSuccess, logoutFailure, logoutRequest, logoutSuccess } from "../../types/Reducer";
import { Dispatch } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';

export const registerUser = async(dispatch:Dispatch, formData:FormData) =>{
    try {
      dispatch({
        type: SignupRequest,
      });
        
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/sign-up`,formData,  
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      Cookies.set('token', response.data.token, { expires: 14 });
 
      dispatch({
        type: SignupSuccess,
        payload: response.data.user,
      });
      window.location.pathname = 'my-profile'
    } catch (error:any) {
      console.log(error);
      debugger
      dispatch({
        type: SignupFailure,
        payload: error.response.data.message,
      });
    }
}

export const loginUser= async(dispatch:Dispatch, formData:FormData) =>{
 
  try {
    dispatch({
      type: LoginRequest,
    });
      
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData);
    Cookies.set('token', response.data.token, { expires: 14});

    dispatch({
      type: LoginSuccess,
      payload: response.data.user,
    });

      window.location.pathname = 'my-profile'
  } catch (error:any) {
    dispatch({
      type: LoginFailure,
      payload:error?.response?.data?.message
    });
  }
};

export const loadUser= async(dispatch:Dispatch) =>{
    try {
        dispatch({
            type:LoadUserRequest
        })

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/myprofile`, {
          withCredentials: true
        });
 
        dispatch({
            type:LoadUserSuccess,
            payload:data.user
        })
  
        return true
    } catch (error:any) { 
        dispatch({
            type:LoadUserFailure,
            payload:error?.response?.data?.message || "Please Login to access this resource"
        })
        return false
    }
}

export const logoutUser = async(dispatch:Dispatch) =>{
  try {
      dispatch({
          type:logoutRequest
      })
      Cookies.remove('token');
      dispatch({
          type:logoutSuccess,
          payload:null
      })
      window.location.pathname = '/';
  } catch (error) {
      dispatch({
          type:logoutFailure,
          payload:error
      })
  }
}

 