import $ from "jquery";
import Header from './components/Header/Header';
import Login from './pages/Login';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import "./css/bootstrap.css"
import "./css/font-awesome.min.css"
import "./css/animate.min.css"
import "./css/style.css"
import "./css/icon.woff2"

// import "./admin/css/admin-style.css"
import SignUp from './pages/SignUp';
 
 
// import "./js/jquery.min.js"
// import "./js/popper.min.js"
// import "./js/bootstrap.min.js"
import "./js/select-opt.js"
// import "./js/Chart.js"
// import "./js/gallery.js"
// import "./js/html5shiv.min.js"
// import "./js/jquery-ui.js"
// import "./js/jquery.validate.min.js"
// import "./js/respond.min.js"
// import "./js/jquery-ui.js"
// import "./js/slick.js"
import "./js/custom.js"

import "./css/jquery-ui.css"
import AllProfile from './pages/AllProfile';
import MyProfile from './pages/MyProfile';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "./types/Reducer";
import { loadUser} from "./Redux/Actions/User";
import Cookies from "js-cookie";
export default function App() {
  // const { isAuthenticated } = useSelector((state: RootState) => state.user); 
   const[isAuthenticated, setIsAuthenticated] = useState<Boolean>(false)
  const dispatch = useDispatch();
  useEffect(() => { 
      if(Cookies.get('token')){
        setIsAuthenticated(true);
      }
  }, [dispatch]);

  
  return (

    <Router>
      <Header />
      <Routes>
       <Route path='/' element={ isAuthenticated === true ?  <MyProfile/> : <Login/>} />
       <Route path='/login' element={<Login/>} />
       <Route path='/sign-up' element={<SignUp />} />
       <Route path='/my-profile' element={isAuthenticated === true ? <MyProfile />  :<Login/>} />
      </Routes>
      <Footer />
    </Router>


  )
}
