import { combineReducers } from "@reduxjs/toolkit";
import { userReducer } from "../Redux/Reducers/User";

export const LoginRequest = 'LoginRequest';
export const LoginSuccess = 'LoginSuccess';
export const LoginFailure = 'LoginFailure';
export const LoadUserRequest = 'LoadUserRequest';
export const LoadUserSuccess = 'LoadUserSuccess';
export const LoadUserFailure = 'LoadUserFailure';
export const logoutRequest = 'logoutRequest';
export const logoutSuccess = 'logoutSuccess';
export const logoutFailure = 'logoutFailure';
export const SignupRequest = 'SignupRequest';
export const SignupSuccess = 'SignupSuccess';
export const SignupFailure = 'SignupFailure';
 
export interface UserState {
    loading: boolean;
    user: any;
    error: string | null;
    isAuthenticated: boolean;
  }

  export interface RootState {
    user: UserState; 
  }

  export const rootReducer = combineReducers({
    user: userReducer, 
});
  
