import React from 'react'
import img1 from '../../images/social/1.png'
import img2 from '../../images/social/2.png'
import img3 from '../../images/social/3.png'
import img5 from '../../images/social/5.png'
 
const Footer = () => {
  return (
    <div> 
  <section className="wed-hom-footer">
    <div className="container">
      <div className="row foot-supp">
        <h2>
          <span>Free support:</span> +92 (8800) 68 - 8960
          &nbsp;&nbsp;|&nbsp;&nbsp; <span>Email:</span>
          info@example.com
        </h2>
      </div>
      <div className="row wed-foot-link wed-foot-link-1">
        <div className="col-md-4">
          <h4>Get In Touch</h4>
          <p>Address: 3812 Lena Lane City Jackson Mississippi</p>
          <p>
            Phone: <a href="tel:+917904462944">+92 (8800) 68 - 8960</a>
          </p>
          <p>
            Email: <a href="mailto:info@example.com">info@example.com</a>
          </p>
        </div>
        <div className="col-md-4">
          <h4>HELP &amp; SUPPORT</h4>
          <ul>
            <li>
              <a href="about-us">About company</a>
            </li>
            <li>
              <a href="#!">Contact us</a>
            </li>
            <li>
              <a href="#!">Feedback</a>
            </li>
            <li>
              <a href="about-us#faq">FAQs</a>
            </li>
            <li>
              <a href="about-us#testimonials">Testimonials</a>
            </li>
          </ul>
        </div>
        <div className="col-md-4 fot-soc">
          <h4>SOCIAL MEDIA</h4>
          <ul>
            <li>
              <a href="#!">
                <img src={img1}alt="" />
              </a>
            </li>
            <li>
              <a href="#!">
                <img src={img2}alt="" />
              </a>
            </li>
            <li>
              <a href="#!">
                <img src={img3} alt="" />
              </a>
            </li>
            <li>
              <a href="#!">
                <img src={img5} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row foot-count">
        <p>
          Company name Site - Trusted by over thousands of Boys &amp; Girls for
          successfull marriage.{" "}
          <a href="sign-up" className="btn btn-primary btn-sm">
            Join us today !
          </a>
        </p>
      </div>
    </div>
  </section>
  {/* END */}
  {/* COPYRIGHTS */}
  <section>
    <div className="cr">
      <div className="container">
        <div className="row">
          <p>
            Copyright © <span id="cry">2017-2020</span>{" "}
            <a href="#!" target="_blank">
              Company.com
            </a>{" "}
            All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </section>
  {/* END */}
  </div>
  )
}

export default Footer